// every dependency has to be imported here
// css, scss, image, fonts etc.
import bulma from "./assets/scss/bulma.scss"; // main bulma styles
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
import './wc-ytgdpr'
import Animate from 'animate.js';
function initAnimateJs() {

  var animate = new Animate({
    target: '[data-animate]',
    animatedClass: 'js-animated',
    offset: [0.25, 0.25],
    delay: 0,
    remove: true,
    scrolled: true,
    reverse: false,
    onLoad: true,
    onScroll: true,
    onResize: false,
    disableFilter: false,
    callbackOnInit: function () {},
    callbackOnInView: function (el) {},
    callbackOnAnimate: function (el) {},
  });
  animate.init();

}

initAnimateJs();
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";

gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin); 


const images = gsap.utils.toArray(".images .timeline-holder");
const listItems = gsap.utils.toArray(".right li");

const tl = gsap.timeline({
  // repeat: -1,
  // repeatDelay: 1,
  scrollTrigger: {
    trigger: ".timeline",
    start: "top top",
    end: "+=200%",
    scrub: true,
    pin: true,
    markers: false
  }
});

images.forEach((image, i) => {
  if (images[i + 1]) {
    tl.set(image, { opacity: 0 }, i + 1)
      .set(listItems[i], { backgroundColor: "transparent" }, "<")
      .set(images[i + 1], { opacity: 1 }, "<")
      .set(listItems[i + 1], { backgroundColor: '#d32f2f' }, "<");
  }
});


document.addEventListener( 'DOMContentLoaded', function() {

  if (document.getElementById('expert') !== null) {
    console.log('FOUND SPLIDE')
    var splide = new Splide( '.expert' );
    splide.mount();
    console.log(splide)
   }
  
  if (document.getElementById('product') !== null) {
    console.log('FOUND SPLIDE')
    var product = new Splide( '.product', {
      type   : 'loop',
      autoplay: true,
      perPage: 4,
      breakpoints: {
        1260: {
          perPage: 2,
        },
        820: {
          perPage: 1,
        }
      }
    } );
    product.mount();
    console.log(product)
   }



 
});

import {
    jarallax,
    jarallaxVideo
  } from 'jarallax';
  
  jarallaxVideo();
  
  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.6,
  });  



const accordionTitles = document.querySelectorAll(".tile-container");
console.log(accordionTitles)
accordionTitles.forEach((accordionTitle) => {
  accordionTitle.addEventListener("click", () => {
    if (accordionTitle.classList.contains("is-open")) {
      accordionTitle.classList.remove("is-open");
    } else {
      const accordionTitlesWithIsOpen = document.querySelectorAll(".is-open");
      accordionTitlesWithIsOpen.forEach((accordionTitleWithIsOpen) => {
        accordionTitleWithIsOpen.classList.remove("is-open");
      });
      accordionTitle.classList.add("is-open");
    }
  });
});


const accordionBtns = document.querySelectorAll(".accordion");

accordionBtns.forEach((accordion) => {
  accordion.onclick = function () {
    this.classList.toggle("is-open");

    let content = this.nextElementSibling;
    console.log(content);

    if (content.style.maxHeight) {
      //this is if the accordion is open
      content.style.maxHeight = null;
    } else {
      //if the accordion is currently closed
      content.style.maxHeight = content.scrollHeight + "px";
      console.log(content.style.maxHeight);
    }
  };
});

document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Add a click event on each of them
  $navbarBurgers.forEach( el => {
    el.addEventListener('click', () => {

      // Get the target from the "data-target" attribute
      const target = el.dataset.target;
      const $target = document.getElementById(target);

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active');
      $target.classList.toggle('is-active');

    });
  });

});